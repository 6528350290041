html, body{
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-family: Lato;
}

#root{
  height: 100%;
  width: 100%;
}