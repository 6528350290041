@import "./scss/breakpoints";

.Main{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  @include media-breakpoint-down(md){
    flex-direction: column;
  }
  &:hover{
    .Header{
      transform: translateY(-100%);
    }
  }
  .Header{
    pointer-events: none;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.60);
    width: 100%;
    padding: 47px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Gray-Light-100, #F8F9FA);
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 130%; /* 46.8px */
    letter-spacing: -0.72px;
    gap: 10px;
    transition: all 0.3s;
    @include media-breakpoint-down(md){
      display: none;
    }
  }
  .Cover{
    cursor: pointer;
    flex: 1;
    overflow: hidden;
    position: relative;
    filter: grayscale(1);
    &:hover{
      filter: grayscale(0);
      .BackgroundImage {
        filter: blur(6px);
      }
    }
    @include media-breakpoint-down(md){
      filter: grayscale(0);
      .BackgroundImage {
        filter: blur(6px);
      }
    }
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .Content{
      position: absolute;
      top: 0;
      left: 0;
      white-space: nowrap;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      &:hover{
        .Title{
          height: 0;
        }
      }

      .Title{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: pre-wrap;
        word-break: break-word;
        text-align: center;
        font-family: Lato;
        font-size: 64px;
        font-weight: 900;
        letter-spacing: -1.28px;
        line-height: 130px;
        transition: all .3s;
        overflow: hidden;
        @include media-breakpoint-down(md){
          height: 0;
        }
      }
      .PanelContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        overflow: hidden;
        @include media-breakpoint-down(md){
          margin: 0.78125rem;
        }
        .Panel{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-radius: 16px;
          background: rgba(255, 255, 255, 0.70);
          padding: 3rem;
          max-width: 632px;
          max-height: 700px;
          @include media-breakpoint-down(md){
            padding: .75rem;
            width: 100%;
            height: 100%;
          }
          &.Primary{
            border: 2px solid var(--Color-Primary, #007DFC);
          }
          &.Danger{
            border: 2px solid var(--Color-Danger, #F64E60);
          }
          .Logo{
            display: flex;
            justify-content: center;
            width: 100%;
            img{
              width: 112px;
              height: 52px;
            }
          }
          .Description{
            padding-top: 4rem;
            padding-bottom: 118px;
            text-align: center;
            word-break: break-all;
            white-space: pre-wrap;
            color: var(--Gray-Dark-800, #343A40);
            font-weight: 900;
            line-height: 8rem;
            letter-spacing: -1.28px;
            @include media-breakpoint-down(md){
              padding-top: 0;
              padding-bottom: 0;
            }
            .H3{
              margin-top: 1rem;
              color: var(--Gray-Dark-800, #343A40);
              font-size: 3rem;
              font-weight: 900;
              line-height: 8rem;
              letter-spacing: -1.28px;
              @include media-breakpoint-down(md){
                font-size: 2.25rem;
                line-height: 130%;
              }
            }
            .H4{
              word-break: break-all;
              white-space: pre-wrap;
              color: var(--Gray-Dark-800, #343A40);
              font-size: 2rem;
              font-weight: 900;
              line-height: 130%; /* 46.8px */
              letter-spacing: -0.72px;
              @include media-breakpoint-down(md){
                font-size: 1.5rem;
                line-height: 100%;
              }
            }
          }
          .Button{
            display: flex;
            padding: 16px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex: 1 0 0;
          }
        }
      }
    }
  }
}
